import GlobalObserver from 'modules/global_observer';

import initNumberField from './init_number_field';

function registerFieldBoxNumberField() {
  GlobalObserver.register('.fieldBox__container--incDec', (containerElement) => {
    initNumberField(containerElement);
  });
}

export default registerFieldBoxNumberField;
