class MutationsProcessor {
  constructor(selector) {
    this.selector = selector;
    this.elements = new Set();
    this.addCallbacks = new Set();
    this.removeCallbacks = new Set();
  }

  registerAdd(callback) {
    this.addCallbacks.add(callback);
  }

  registerRemove(callback) {
    this.removeCallbacks.add(callback);
  }

  processMutations(mutations) {
    for (const mutation of mutations) {
      this.processMutation(mutation);
    }
  }

  processMutation(mutation) {
    this.processAddedNodes(mutation.addedNodes);
    this.processRemovedNodes(mutation.removedNodes);
  }

  processAddedNodes(nodes) {
    for (const node of Array.from(nodes)) {
      this.processNode(node, this.addElement);
    }
  }

  processRemovedNodes(nodes) {
    for (const node of Array.from(nodes)) {
      this.processNode(node, this.removeElement);
    }
  }

  processNode(node, callback) {
    if (node.nodeType !== Node.ELEMENT_NODE) {
      return;
    }

    const matches = this.matchElementsInTree(node);

    for (const element of matches) {
      callback.call(this, element);
    }
  }

  addElement(element) {
    if (!this.elements.has(element)) {
      this.elements.add(element);

      this.addCallbacks.forEach(callback => {
        callback.call(null, element);
      });
    }
  }

  removeElement(element) {
    if (this.elements.has(element)) {
      this.elements.delete(element);

      this.removeCallbacks.forEach(callback => {
        callback.call(null, element);
      });
    }
  }

  matchElementsInTree(tree) {
    const match = tree.matches(this.selector) ? [tree] : [];
    const matches = Array.from(tree.querySelectorAll(this.selector));

    return match.concat(matches);
  }
}

export default MutationsProcessor;