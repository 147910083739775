import GlobalObserver from 'modules/global_observer';

import initInputField from './init_input_field';

function registerFieldLine() {
  GlobalObserver.register('.fieldLine__container', (containerElement) => {
    initInputField('fieldLine', containerElement);
  });
}

export default registerFieldLine;
