import registerChoices from './register_choices';
import registerFieldBox from './register_field_box';
import registerFieldBoxFileField from './register_field_box_file_field';
import registerFieldBoxNumberField from './register_field_box_number_field';
import registerFieldLine from './register_field_line';
import './styles/animated_checkbox.css';
import './styles/animated_radio.css';
import './styles/animated_switcher.css';
import './styles/field_box.css';
import './styles/field_line.css';

function registerInputFields() {
  registerFieldBox();
  registerFieldBoxNumberField();
  registerFieldBoxFileField();
  registerFieldLine();
  registerChoices();
}

export default registerInputFields;
