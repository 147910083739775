import addListener from 'plugins/utilities/add_listener';
import getData from 'plugins/data/get';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import setData from 'plugins/data/set';

const INPUT_ELEMENTS = ['input', 'select', 'textarea'];

function isInputElement(element) {
  return INPUT_ELEMENTS.indexOf(element.tagName.toLowerCase()) >= 0;
}

function setFilled(klass, fieldElement, filled) {
  const filledClass = `${klass}--filled`;
  fieldElement.classList.toggle(filledClass, filled);
}

function isFilled(valueElement) {
  let value = valueElement.value;

  if (isVariableDefinedNotNull(value)) {
    value = value.trim();
  }

  if (valueElement.tagName.toLowerCase() === 'select') {
    if (isVariableDefinedNotNull(value) && value !== '') {
      return true;
    }

    if (valueElement.multiple) {
      return valueElement.options.length > 0;
    }

    const option = valueElement.options[valueElement.selectedIndex];

    if (isVariableDefinedNotNull(option) && option.text.trim() !== '') {
      return true;
    }
  }

  return isVariableDefinedNotNull(value) && value !== '';
}

function openLink(klass, element) {
  const containerElement = element.closest('.fieldBox__container');
  const fieldElement = containerElement.querySelector(`.${klass}`);
  let link;

  const choicesElement = containerElement.querySelector('select.choices__input');
  if (choicesElement && choicesElement.__choices) {
    let customProperties = choicesElement.__choices.getValue().customProperties;
    if (customProperties) {
      if (typeof customProperties === 'string') {
        customProperties = JSON.parse(customProperties);
      }

      link = customProperties.url;
    }
  } else if (fieldElement.hasAttribute('data-url')) {
    link = fieldElement.getAttribute('data-url');
  } else {
    link = fieldElement.value;
  }

  if (isVariableDefinedNotNull(link) && link !== '') {
    if (element.hasAttribute('data-linkify-template')) {
      const template = element.getAttribute('data-linkify-template');
      link = template.replace('%value%', link);
    }

    window.open(link, '_blank');
  }
}

function focusIn(klass, valueElement, classElement) {
  if (!valueElement.readOnly) {
    setData(classElement, 'focused', true);
    setFilled(klass, classElement, true);
  }
}

function focusOut(klass, valueElement, classElement) {
  if (!valueElement.readOnly) {
    setData(classElement, 'focused', false);
    setFilled(klass, classElement, isFilled(valueElement));
  }
}

function change(klass, valueElement, classElement) {
  if (valueElement !== document.activeElement) {
    setFilled(klass, classElement, isFilled(valueElement));
  }
}

function getValueTargetElement(klass, fieldElement) {
  if (fieldElement.classList.contains('choices__input')) {
    return fieldElement.closest(`.${klass}`).querySelector(`.${klass}`);
  }

  return fieldElement;
}

function getClassTargetElement(klass, element) {
  if (element.classList.contains(`.${klass}`)) {
    return element;
  }

  const target = element.querySelector(`.${klass}`);
  if (target) {
    return target;
  }

  return element.closest(`.${klass}`);
}

function initInputField(klass, containerElement) {
  for (const fieldElement of containerElement.querySelectorAll(`.${klass}`)) {
    if (isFilled(fieldElement)) {
      setFilled(klass, fieldElement, true);
    }
  }

  addListener(containerElement, 'click', event => {
    if (event.target.classList.contains(`${klass}__openLink`)) {
      event.preventDefault();

      openLink(klass, event.target);
    }
  });

  addListener(containerElement, 'focusin', event => {
    if (isInputElement(event.target)) {
      focusIn(klass, getValueTargetElement(klass, event.target), getClassTargetElement(klass, event.target));
    }
  });

  addListener(containerElement, 'focusout', event => {
    if (isInputElement(event.target)) {
      focusOut(klass, getValueTargetElement(klass, event.target), getClassTargetElement(klass, event.target));
    }
  });

  addListener(containerElement, 'change', event => {
    if (isInputElement(event.target)) {
      const classTarget = getClassTargetElement(klass, event.target);
      if (!getData(classTarget, 'focused', false)) {
        change(klass, event.target, classTarget);
      }
    }
  });
}

export default initInputField;
