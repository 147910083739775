import addListener from 'plugins/utilities/add_listener';
import fire from 'plugins/utilities/fire';
import setData from 'plugins/data/set';
import getData from 'plugins/data/get';

function initNumberField(element) {
  if (getData(element, 'numberFieldInitialized', false)) {
    return;
  }

  setData(element, 'numberFieldInitialized', true);

  const inputElement = element.getElementsByTagName('input')[0];
  const incrementElement = element.querySelector(
    '[data-action="increment"], [data-counter-action="increment"]',
  );
  const decrementElement = element.querySelector(
    '[data-action="decrement"], [data-counter-action="decrement"]',
  );

  const min = inputElement.hasAttribute('data-min')
    ? parseInt(inputElement.getAttribute('data-min'), 10)
    : null;
  const max = inputElement.hasAttribute('data-max')
    ? parseInt(inputElement.getAttribute('data-max'), 10)
    : null;

  addListener(incrementElement, 'click', () => {
    const currentValue = parseInt(inputElement.value, 10) || 0;
    let newValue = currentValue + 1;

    if (max !== null) {
      newValue = Math.min(max, newValue);
    }

    if (currentValue !== newValue) {
      inputElement.value = newValue;
      fire(inputElement, 'change');
    }
  });

  addListener(decrementElement, 'click', () => {
    const currentValue = parseInt(inputElement.value, 10) || 0;
    let newValue = currentValue - 1;
    if (min !== null) {
      newValue = Math.max(min, newValue);
    }

    if (currentValue !== newValue) {
      inputElement.value = newValue;
      fire(inputElement, 'change');
    }
  });
}

export default initNumberField;
