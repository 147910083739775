import GlobalObserver from 'modules/global_observer';

import initFileField from './init_file_field';

function registerFieldBoxFileField() {
  GlobalObserver.register('.fieldBox__container--file', (containerElement) => {
    initFileField(containerElement);
  });
}

export default registerFieldBoxFileField;
