import addListener from 'plugins/utilities/add_listener';
import isVariableDefinedNotNull from 'plugins/utilities/is_variable_defined_not_null';
import setData from 'plugins/data/set';
import getData from 'plugins/data/get';

function initFileField(element) {
  if (getData(element, 'fileFieldInitialized', false)) {
    return;
  }

  setData(element, 'fileFieldInitialized', true);

  const replaceWithTextElement = element.querySelector('[data-replace-with-text="true"]');

  addListener(element, 'change', 'input', event => {
    const files = event.target.files;

    if (files.length === 0) {
      if (isVariableDefinedNotNull(replaceWithTextElement.dataset.defaultTextContent)) {
        replaceWithTextElement.textContent = replaceWithTextElement.dataset.defaultTextContent;
      }
    } else {
      if (!isVariableDefinedNotNull(replaceWithTextElement.dataset.defaultTextContent)) {
        replaceWithTextElement.dataset.defaultTextContent = replaceWithTextElement.textContent;
      }

      replaceWithTextElement.textContent = files[0].name;
    }
  });
}

export default initFileField;
