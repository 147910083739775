import getData from 'plugins/data/get';
import setData from 'plugins/data/set';
import MutationsProcessor from './mutations_processor';

class GlobalObserver {
  static globalObserver() {
    if (!window.global_observer_) {
      window.global_observer_ = new GlobalObserver();
      window.global_observer_.start();
    }

    return window.global_observer_;
  }

  static register(selector, callbackAdd, callbackRemove = null) {
    this.globalObserver().register(selector, callbackAdd, callbackRemove);
  }

  start() {
    this.observer = new MutationObserver((mutations) => this.processMutations(mutations));
    this.processors = new Map();

    const options = {
      childList: true,
      subtree: true,
    };

    this.observer.observe(document, options);
  }

  register(selector, callbackAdd, callbackRemove = null) {
    let processor = this.processors.get(selector);
    if (processor === undefined) {
      processor = new MutationsProcessor(selector);

      this.processors.set(selector, processor);
    }

    processor.registerAdd(callbackAdd);

    if (callbackRemove) {
      processor.registerRemove(callbackRemove);
    }

    for (const target of document.querySelectorAll(selector)) {
      if (getData(target, `GlobalObserver__${selector}`)) continue;

      setData(target, `GlobalObserver__${selector}`, true);
      callbackAdd(target);
    }
  }

  processMutations(mutations) {
    this.processors.forEach((processor) => {
      processor.processMutations(mutations);
    });
  }
}

export default GlobalObserver;
