import GlobalObserver from 'modules/global_observer';

import initInputField from './init_input_field';

function registerFieldBox() {
  GlobalObserver.register('.fieldBox__container', (containerElement) => {
    initInputField('fieldBox', containerElement);
  });
}

export default registerFieldBox;
